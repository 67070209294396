<template>
  <div class="comparable-sale-box">
    <Subject-Detail :data="data" :info="filterInfo" :flag="flag" @viewComparable="viewComparable" ref="view">
      <template slot="price-title">
            <div class="row item-common  item-row">
                 <span class="item-title">Asking Price Range (000s)</span>
                  <span class="item-contact">{{listPrice | currencyPrice("",0)}}</span>
            </div>
        </template>
      <template slot="name">Comp Listing Criteria</template>
       <template slot="price">
               <div class="row  item-common ">
                 <el-switch v-model="priceShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                 <div v-show="priceShow" class="price-row row">
                   <el-input v-model="min_price" @input="e=>min_price = changePrice(e)" placeholder="Min Price"/>
                   <el-input v-model="max_price" @input="e=>max_price = changePrice(e)" placeholder="Max Price"/>
                 </div>
               </div>
           </template>
      <template slot="btn-name">View Competitive Listings</template>
      <template slot="number">
        <p class="show-number" v-if="total">{{total}} matches</p> 
      </template>
    </Subject-Detail>
    <div>
      <Listing-Common :data="filterData" @addComment="addComment" @addReport="addReport" @removeListData="removeListData" ></Listing-Common>
      <!-- <Add-Comments></Add-Comments> -->
    </div>
    <div class="btn-row row">
      <p class="btn-box back row" @click="prev">
        <i class="el-icon-back"></i><span>Previous</span>
      </p>
      <p class="btn-box row" @click="next">
        <span>Next Step</span><i class="el-icon-right"></i>
      </p>
    </div>
    <Add-Comment-Dialog ref="comment" @save="save"></Add-Comment-Dialog>
  </div>
</template>
<script>
import { apiGetLinkListings, apiCreatedLinkListingComment, } from "../../API/api";
import ListingCommon from "./Compnents/ListingsCommon";
import AddComments from "./Compnents/AddComments";
import SubjectDetail from "./Compnents/SubjectDetail";
import AddCommentDialog from "./Compnents/AddCommentDialog";
import { Minixs } from "../../js/mixins";
function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
     
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
export default {
  mixins: [Minixs],
  components: { ListingCommon, AddComments, SubjectDetail, AddCommentDialog },
  data() {
    return {
      priceShow: true,
      data: {},
      form: {status: ["A",'C'], publish:true,exclude:this.$route.params.id},
      filterData: [],
      id: "", //评论link 的id
      min_price:"",
      max_price:"",
      filterInfo:{
        sewer:[], property_type2:[], dwell2:[], zoning:[],view:[],FrontageType:[],
        area:[],lot_size_square_feet_gte:null,lot_size_square_feet_lte:null,building_area_total_gte:null,building_area_total_lte:null,
        bedroom_num_gte:null,bedroom_num_lte:null,year_built_gte:null,year_built_lte:null,
      },
      total:null,
      flag: true,
    };
  },
  computed:{
    listPrice(){
        let number = Math.round(this.data.OriginalListPrice / 1000)
        return number
    }
  },
  methods: {
    save(comment) {
      apiCreatedLinkListingComment(this.id, { content: comment })
        .then((res) => {
          this.$message.success("Success");
        })
        .finally(() => {
          this.$refs.comment.show = false;
        });
    },
    viewComparable(value) {
      let obj = {status: ["A",'C'], publish: true, exclude :this.$route.params.id};
      if( this.priceShow ){
        if( this.min_price ){
          let num1 = this.min_price.split(",").join("");
           obj.min_price = num1 *1000
           }
        if( this.max_price ) { 
          let num2 = this.max_price.split(",").join("");
          obj.max_price = num2 * 1000
          }
      }
      this.form = Object.assign( {}, obj, value );
      this.$store.commit('setCompetitiveFilterCondition', this.form);
      this.getFilterLink();
    },
    getFilterLink() {
      apiGetLinkListings(this.form)
        .then((res) => {
           this.filterData =[];
           this.total = res.count;
           this.$store.commit('setCompetitiveNumber', res.count);
          if (res.count > 30) {
            let obj = { limit: res.count };
            this.form = Object.assign({}, this.form, obj);
            apiGetLinkListings(this.form).then((res) => {
              this.filterData = res.results;
              let arr =[]
              this.filterData.forEach((element) => {
                arr.push(element.ListingId);
              });
              this.$store.commit("setCompetitiveFilterInfo", this.filterData);
              this.$store.commit("setCompetitiveListings", arr);
            }).finally(() => {
              this.$refs.view.loading = false;
            });
          } else {
            let arr = [];
            if(res.count === 0){
              this.$message.success('Your search did not match any listings.')
            }
            this.filterData = res.results;
            this.filterData.forEach((element) => {
              arr.push(element.ListingId);
            });
            this.$store.commit("setCompetitiveFilterInfo", this.filterData);
            this.$store.commit("setCompetitiveListings", arr);
            this.$refs.view.loading = false;
          }
        })
        .catch(() => {
          this.$refs.view.loading = false;
        });
    },
    addComment(id) {
      this.$refs.comment.show = true;
      this.id = id;
    },
    addReport(id) {
      let arr = [];
      this.$store.state.linksAllId.push(id);
      arr = this.$store.state.linksAllId;
      this.$store.commit("setlinksAllId", arr);
    },
    removeListData(id, index) {
      this.filterData.forEach((n, i) => {
        if (id === n.ListingId) {
          this.filterData.splice(index, 1);
        }
      });
       let arr =[]
       this.filterData.forEach((element) => {
          arr.push(element.ListingId);
       });
      this.$store.commit("setCompetitiveListings", arr);
    },
    getLinkListingDetail() {
      apiGetLinkListings({ link_id: this.$route.params.id }).then((res) => {
        this.data = res.results[0];
        this.changeData(res.results[0])
        
      });
    },
    changeData(data){
        if( this.$store.state.competitive_filter_condition&&JSON.stringify(this.$store.state.competitive_filter_condition)!='{}' ){
          this.flag = true;
              this.min_price = number_format(Math.round((this.$store.state.competitive_filter_condition.min_price) / 1000));
              this.max_price = number_format(Math.round((this.$store.state.competitive_filter_condition.max_price) / 1000));
              this.filterInfo.property_type2=this.$store.state.competitive_filter_condition.property_type2;
              this.filterInfo.area = this.$store.state.competitive_filter_condition.area;
              this.filterInfo.zoning = this.$store.state.competitive_filter_condition.zoning;
              this.filterInfo.dwell2 = this.$store.state.competitive_filter_condition.dwell2;
              this.filterInfo.sewer = this.$store.state.competitive_filter_condition.sewer;
              this.filterInfo.view = this.$store.state.competitive_filter_condition.view;
              this.filterInfo.FrontageType = this.$store.state.competitive_filter_condition.FrontageType;
              this.filterInfo.FrontageType = this.$store.state.competitive_filter_condition.FrontageType;
              this.filterInfo.lot_size_square_feet_gte = this.$store.state.competitive_filter_condition.lot_size_square_feet_gte ;
              this.filterInfo.lot_size_square_feet_lte = this.$store.state.competitive_filter_condition.lot_size_square_feet_lte ;
              this.filterInfo.bedroom_num_gte = this.$store.state.competitive_filter_condition.bedroom_num_gte;
              this.filterInfo.bedroom_num_lte = this.$store.state.competitive_filter_condition.bedroom_num_gte;
              this.filterInfo.building_area_total_gte = this.$store.state.competitive_filter_condition.building_area_total_gte;
              this.filterInfo.building_area_total_lte = this.$store.state.competitive_filter_condition.building_area_total_lte;
              this.filterInfo.year_built_gte = this.$store.state.competitive_filter_condition.year_built_gte;
              this.filterInfo.year_built_lte = this.$store.state.competitive_filter_condition.year_built_lte;
        }else{
          this.flag = false;
          this.min_price = number_format(Math.round((this.data.ListPrice - this.data.ListPrice * 0.05) / 1000));
          this.max_price = number_format(Math.round((this.data.ListPrice + this.data.ListPrice * 0.05) / 1000));
          if(data.PropertyType==='Commercial'){
              this.filterInfo.property_type2.push('Commercial');
            }else if(data.PropertyType==='1 Family'||data.PropertyType==='Single Family'){
              this.filterInfo.property_type2.push('Single Family');
            }else if(data.PropertyType==='Land'){
              this.filterInfo.property_type2.push('Land');
            }else { this.filterInfo.property_type2.push('Condo/Multi Family'); }
            this.filterInfo.area.push(data.MLSAreaMajor);
            this.filterInfo.zoning.push(data.Zoning);
            this.filterInfo.dwell2.push(data.LINK_listing_dwell2);
            this.filterInfo.sewer = data.Sewer;
            this.filterInfo.view = data.View;
            this.filterInfo.FrontageType  = data.FrontageType;
            if(this.data.LotSizeSquareFeet) {
              this.filterInfo.lot_size_square_feet_gte = Math.floor(data.LotSizeSquareFeet - data.LotSizeSquareFeet * 0.1);
              this.filterInfo.lot_size_square_feet_lte = Math.ceil(data.LotSizeSquareFeet + data.LotSizeSquareFeet * 0.1);
            }
            if(this.data.BedroomsTotal) {
              this.filterInfo.bedroom_num_gte = data.BedroomsTotal - 1 ;
              this.filterInfo.bedroom_num_lte = data.BedroomsTotal + 1 ;
            }
            if(this.data.BuildingAreaTotal) {
              this.filterInfo.building_area_total_gte = Math.floor(data.BuildingAreaTotal - data.BuildingAreaTotal * 0.1) ;
              this.filterInfo.building_area_total_lte = Math.ceil(data.BuildingAreaTotal + data.BuildingAreaTotal * 0.1);
            }
            if(this.data.YearBuilt) {
              this.filterInfo.year_built_gte = (Number(data.YearBuilt) - 5)+'/01'+"/01";
              this.filterInfo.year_built_lte = (Number(data.YearBuilt) + 5)+'/01'+"/01";
            } 
          }
    },
    prev(){ 
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.2)'
        });
        setTimeout(() => {
          loading.close();
        }, 1000);
        this.$emit("updateTab","/comparable-sales")
      this.$router.push({name:"comparable-sales",params:this.$route.query.id }) 
    },
    next(){ 
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.2)'
        });
        setTimeout(() => {
          loading.close();
        }, 1000);
        this.$emit("updateTab","/report-settings")
      this.$router.push({name:"report-settings",params:this.$route.query.id }) 
      }
  },
  created() {
    if (this.$route.params.id) {
      this.getLinkListingDetail();
    }
    if(this.$store.state.cometitive_number) this.total = this.$store.state.cometitive_number;
    if( this.$store.state.competitive_filter_info&&this.$store.state.competitive_filter_info.length!=0 ){
       this.filterData = this.$store.state.competitive_filter_info;
    }
  },
};
</script>
<style lang="scss" scoped>
.comparable-sale-box {
  margin-bottom: 50px;
  .btn-row {
    display: flex;
    -webkit-justify-content:center;
    justify-content: center;
    padding: 0 20px;
    .btn-box {
      width: 95px;
      background-color: #678993;
      height: 36px;
      line-height: 36px;
      color: #fff;
      justify-content: space-between;
      padding: 0 20px;
      border-radius: 2px;
      cursor: pointer;
    }
    .back {
      background-color: #e2eff6;
      color: #38425b;
      margin-right: 20px;
    }
  }
}
</style>